// Internal: Formats that don't require a time to be present.
const PlainDateFormats = {
  DATE: 'MM/dd/yyyy',
  DATE_ISO: 'yyyy-MM-dd',
  DATE_LONG: 'dd MMMM yyyy',
  DATE_SHORT: 'MMM d',
  WEEKDAY: 'EEEE',
}

// Internal: Aliases that simplify formatting dates in the application and
// help to provide consistency.
export const DateFormats = {
  ...PlainDateFormats,
  DATE_TIME: 'MM/dd/yyyy hh:mm a',
  DATE_TIME_WITH_DAY: 'EEE, MM/dd/yyyy hh:mm a',
  DATE_TIME_TIMEZONE: 'MM/dd/yyyy hh:mm a z',
  DATE_TIME_TIMEZONE_V2: 'MMM dd, yyyy hh:mm a z',
  TIME: 'hh:mm a',
  TIME_ISO: 'HH:mm',
  COUNTDOWN: 'mm:ss',
}

// Public: Convert the date format alias to the actual format
export function formatForAlias (formatName, formats) {
  if (!formatName) throw new Error(`No date format was provided: ${formatName}`)
  return DateFormats[formatName.toUpperCase()] ||
    throw new TypeError(`Invalid date format '${formatName}', check '@constants/DateFormats'`)
}

// Public: Returns true if the format does not need time information.
export function isPlainDateFormat (formatName) {
  return Boolean(PlainDateFormats[String(formatName).toUpperCase()])
}
